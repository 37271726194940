import { NgModule } from "@angular/core";

import { DragDropModule } from '@angular/cdk/drag-drop';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule as ACommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EmojiModule } from '@banta/sdk';
import { TYTCommonModule } from '@tytapp/common';
import { ImageCropperDialogComponent } from '@tytapp/editor-common/image-cropper-dialog/image-cropper-dialog.component';
import { CommonUiModule } from '@tytapp/common-ui';
import { ImageCropperModule } from "ngx-image-cropper";
import { UserModule } from '@tytapp/user';
import { AvAssetSearchComponent } from "./av-asset-search/av-asset-search.component";
import { DatepickerComponent } from "./datepicker/datepicker.component";
import { IconEditorComponent, IconEditorDialogComponent } from './icon-editor/icon-editor.component';
import { ImageAssetComponent } from "./image-asset/image-asset.component";
import { MarkdownFieldComponent } from './markdown-field.component';
import { TalentSearchComponent } from "./talent-search/talent-search.component";
import { UrlFieldComponent } from './url-field.component';
import { MatDialogModule } from '@angular/material/dialog';
import { LinkEditorComponent } from './link-editor/link-editor.component';

const COMPONENTS = [
    TalentSearchComponent,
    ImageAssetComponent,
    DatepickerComponent,
    AvAssetSearchComponent,
    ImageCropperDialogComponent,
    MarkdownFieldComponent,
    UrlFieldComponent,
    IconEditorComponent,
    IconEditorDialogComponent,
    LinkEditorComponent
];

@NgModule({
    declarations: COMPONENTS,
    exports: COMPONENTS,
    imports: [
        HttpClientModule,
        FormsModule,
        ACommonModule,
        TYTCommonModule,
        CommonUiModule,
        UserModule,
        EmojiModule,
        TextFieldModule,
        ImageCropperModule,
        DragDropModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatInputModule,
        MatTabsModule,
        MatMenuModule,
        MatDialogModule,
        MatSelectModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatDatepickerModule
    ]
})
export class EditorCommonModule {

}
