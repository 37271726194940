"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAll = exports.existsOne = exports.findOne = exports.findOneChild = exports.find = exports.filter = void 0;
var domhandler_1 = require("domhandler");
/**
 * Search a node and its children for nodes passing a test function. If `node` is not an array, it will be wrapped in one.
 *
 * @category Querying
 * @param test Function to test nodes on.
 * @param node Node to search. Will be included in the result set if it matches.
 * @param recurse Also consider child nodes.
 * @param limit Maximum number of nodes to return.
 * @returns All nodes passing `test`.
 */
function filter(test, node, recurse, limit) {
  if (recurse === void 0) {
    recurse = true;
  }
  if (limit === void 0) {
    limit = Infinity;
  }
  return find(test, Array.isArray(node) ? node : [node], recurse, limit);
}
exports.filter = filter;
/**
 * Search an array of nodes and their children for nodes passing a test function.
 *
 * @category Querying
 * @param test Function to test nodes on.
 * @param nodes Array of nodes to search.
 * @param recurse Also consider child nodes.
 * @param limit Maximum number of nodes to return.
 * @returns All nodes passing `test`.
 */
function find(test, nodes, recurse, limit) {
  var result = [];
  /** Stack of the arrays we are looking at. */
  var nodeStack = [nodes];
  /** Stack of the indices within the arrays. */
  var indexStack = [0];
  for (;;) {
    // First, check if the current array has any more elements to look at.
    if (indexStack[0] >= nodeStack[0].length) {
      // If we have no more arrays to look at, we are done.
      if (indexStack.length === 1) {
        return result;
      }
      // Otherwise, remove the current array from the stack.
      nodeStack.shift();
      indexStack.shift();
      // Loop back to the start to continue with the next array.
      continue;
    }
    var elem = nodeStack[0][indexStack[0]++];
    if (test(elem)) {
      result.push(elem);
      if (--limit <= 0) return result;
    }
    if (recurse && (0, domhandler_1.hasChildren)(elem) && elem.children.length > 0) {
      /*
       * Add the children to the stack. We are depth-first, so this is
       * the next array we look at.
       */
      indexStack.unshift(0);
      nodeStack.unshift(elem.children);
    }
  }
}
exports.find = find;
/**
 * Finds the first element inside of an array that matches a test function. This is an alias for `Array.prototype.find`.
 *
 * @category Querying
 * @param test Function to test nodes on.
 * @param nodes Array of nodes to search.
 * @returns The first node in the array that passes `test`.
 * @deprecated Use `Array.prototype.find` directly.
 */
function findOneChild(test, nodes) {
  return nodes.find(test);
}
exports.findOneChild = findOneChild;
/**
 * Finds one element in a tree that passes a test.
 *
 * @category Querying
 * @param test Function to test nodes on.
 * @param nodes Node or array of nodes to search.
 * @param recurse Also consider child nodes.
 * @returns The first node that passes `test`.
 */
function findOne(test, nodes, recurse) {
  if (recurse === void 0) {
    recurse = true;
  }
  var elem = null;
  for (var i = 0; i < nodes.length && !elem; i++) {
    var node = nodes[i];
    if (!(0, domhandler_1.isTag)(node)) {
      continue;
    } else if (test(node)) {
      elem = node;
    } else if (recurse && node.children.length > 0) {
      elem = findOne(test, node.children, true);
    }
  }
  return elem;
}
exports.findOne = findOne;
/**
 * Checks if a tree of nodes contains at least one node passing a test.
 *
 * @category Querying
 * @param test Function to test nodes on.
 * @param nodes Array of nodes to search.
 * @returns Whether a tree of nodes contains at least one node passing the test.
 */
function existsOne(test, nodes) {
  return nodes.some(function (checked) {
    return (0, domhandler_1.isTag)(checked) && (test(checked) || existsOne(test, checked.children));
  });
}
exports.existsOne = existsOne;
/**
 * Search an array of nodes and their children for elements passing a test function.
 *
 * Same as `find`, but limited to elements and with less options, leading to reduced complexity.
 *
 * @category Querying
 * @param test Function to test nodes on.
 * @param nodes Array of nodes to search.
 * @returns All nodes passing `test`.
 */
function findAll(test, nodes) {
  var result = [];
  var nodeStack = [nodes];
  var indexStack = [0];
  for (;;) {
    if (indexStack[0] >= nodeStack[0].length) {
      if (nodeStack.length === 1) {
        return result;
      }
      // Otherwise, remove the current array from the stack.
      nodeStack.shift();
      indexStack.shift();
      // Loop back to the start to continue with the next array.
      continue;
    }
    var elem = nodeStack[0][indexStack[0]++];
    if (!(0, domhandler_1.isTag)(elem)) continue;
    if (test(elem)) result.push(elem);
    if (elem.children.length > 0) {
      indexStack.unshift(0);
      nodeStack.unshift(elem.children);
    }
  }
}
exports.findAll = findAll;
