"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var html_1 = require("./nodes/html");
/**
 * Parses HTML and returns a root element
 * Parse a chuck of HTML source.
 */
function valid(data, options) {
  if (options === void 0) {
    options = {};
  }
  var stack = (0, html_1.base_parse)(data, options);
  return Boolean(stack.length === 1);
}
exports.default = valid;