<mat-form-field appearance="outline" floatLabel="always">
  <mat-label>{{label}}</mat-label>
  <input type="datetime" name="date" matInput [matDatepicker]="picker" [(ngModel)]="value" [disabled]="disabled" />
  <span matSuffix>
    @if (value) {
      <a mat-icon-button matTooltip="Clear this value" (click)="setValue(null)" [disabled]="disabled">
        <mat-icon>clear</mat-icon>
      </a>
    }
    <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
  </span>

  <mat-datepicker #picker></mat-datepicker>
  <mat-hint>
    <div class="spacer">
      <ng-content selector=".hint"></ng-content>
    </div>
    <a href="javascript:;" (click)="setDateToday()" [class.disabled]="disabled">
      <mat-icon>today</mat-icon>
      Today
    </a>
    <a href="javascript:;" (click)="setDateTomorrow()" [class.disabled]="disabled">
      <mat-icon>schedule_send</mat-icon>
      Tomorrow
    </a>
    <a href="javascript:;" (click)="setDateNextMonday()" [class.disabled]="disabled">
      <mat-icon>calendar_view_week</mat-icon>
      Monday
    </a>
  </mat-hint>
</mat-form-field>