<h1>Edit Icon</h1>

<div class="editor">
  <tyt-icon [icon]="icon"></tyt-icon>

  <div class="form">
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Type</mat-label>
      <mat-select [(ngModel)]="icon.type">
        <mat-option value="none">None</mat-option>
        <mat-option value="material">Material</mat-option>
        <mat-option value="emoji">Emoji</mat-option>
        <mat-option value="image">Image</mat-option>
      </mat-select>
    </mat-form-field>

    @if (icon.type === 'none') {
      No icon will be shown
    }
    @if (icon.type === 'material') {
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Icon</mat-label>
        <input matInput type="text" [(ngModel)]="icon.text" />
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always" class="search">
        <mat-label>Search</mat-label>
        <input matInput type="text" [(ngModel)]="materialIconQuery" />
      </mat-form-field>
      <div class="icon-list">
        @for (availableIcon of filteredMaterialIcons; track availableIcon) {
          <button class="icon"
            (click)="icon.text = availableIcon" [class.selected]="icon.text === availableIcon">
            <mat-icon>{{ availableIcon }}</mat-icon>
            <div class="label">{{ availableIcon }}</div>
          </button>
        }
      </div>
    }
    @if (icon.type === 'emoji') {
      <emoji-selector-panel (selected)="icon.text = $event"></emoji-selector-panel>
    }
    @if (icon.type === 'image') {
      <tyt-image-asset [(url)]="icon.url" [aspectRatio]="1/1" [recommendedAspectRatio]="1/1"
      recommendedAspectRatioLabel="1:1"></tyt-image-asset>
    }

    <div class="actions">
      <button mat-button (click)="close()">Cancel</button>
      <button mat-raised-button color="primary" (click)="apply()">Apply</button>
    </div>
  </div>
</div>