import { Component, Input, Output, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { NodeHtmlMarkdown, PostProcessResult } from 'node-html-markdown';
import { MessageDialogComponent, Shell } from '@tytapp/common';

@Component({
    selector: 'tyt-markdown-field',
    template: `
        <div style="text-align: right; font-size: 10px; position: absolute; right: 3em; top: 1.5em; z-index: 100;">
            <a matTooltip="Learn how to use Markdown" href="javascript:;" (click)="markdownReference()">(Reference)</a>
        </div>
        <mat-form-field class="required" floatLabel="always">
            <mat-label>{{ label }}</mat-label>
            <textarea name="text" matInput
                    autosize
                    [ngModel]="value"
                    (ngModelChange)="valueChange.next($event)"
                    [placeholder]="placeholder"
                    [disabled]="disabled"
                    (keydown)="keydown.next($event)"
                    (paste)="handlePaste($event)"
            ></textarea>
        </mat-form-field>
    `,
    styles: [
        `
        :host {
            display: block;
            position: relative;
        }

        mat-form-field {
            width: 100%;
        }
        `
    ]
})
export class MarkdownFieldComponent {
    private shell = inject(Shell);

    @Output() keydown = new Subject<KeyboardEvent>();
    @Input() label;
    @Input() placeholder = '';
    @Input() disabled = false;

    @Input() value: string;
    @Output() valueChange = new Subject<string>();

    handlePaste(event: ClipboardEvent) {
        // BUG-WORKAROUND
        // for https://github.com/crosstype/node-html-markdown/issues/36
        if (typeof process === 'undefined') {
            (window as any).process = { env: {} };
        }

        if (event.clipboardData.files.length > 0) {
            event.stopPropagation();
            event.preventDefault();
            alert(`You cannot paste files into an article block.`);
            return;
        }

        let html = event.clipboardData.getData('text/html');

        if (!html)
            return;

        let doc = document.implementation.createHTMLDocument();
        doc.write(html);

        let changed = true;
        while (changed) {
            changed = false;

            // Google Docs can generate <b style="font-weight: normal">, that should not be considered "bold" (**)
            for (let el of Array.from(doc.querySelectorAll('b'))) {
                if (el && el.style.fontWeight === 'normal') {
                    let span = doc.createElement('span');
                    el.getAttributeNames().forEach(attr => span.setAttribute(attr, el.getAttribute(attr)));
                    span.innerHTML = el.innerHTML;
                    el.replaceWith(span);
                    changed = true;
                }
            }

            // Detect a bold span and separate it out into a <b> tag (again, Google Docs)
            for (let el of Array.from(doc.querySelectorAll('span'))) {
                if (el && (Number(el.style.fontWeight) >= 700 || ['bold', 'bolder'].includes(el.style.fontWeight))) {
                    el.style.fontWeight = '';

                    let b = doc.createElement('b');
                    el.replaceWith(b);
                    b.appendChild(el);
                    changed = true;
                }
            }

            // Detect an italic span and separate it out into an <i> tag (again, Google Docs)
            for (let el of Array.from(doc.querySelectorAll('span'))) {
                if (['italic', 'oblique'].includes(el.style.fontStyle)) {
                    el.style.fontStyle = '';

                    let em = doc.createElement('em');
                    el.replaceWith(em);
                    em.appendChild(el);
                    changed = true;
                }
            }

            // Detect an underlined span and separate it out into an <u> tag (again, Google Docs)
            for (let el of Array.from(doc.querySelectorAll('span'))) {
                if (['underline'].includes(el.style.textDecoration)) {
                    el.style.textDecoration = '';

                    let em = doc.createElement('u');
                    el.replaceWith(em);
                    em.appendChild(el);
                    changed = true;
                }
            }
        }

        let originalHtml = html;
        html = doc.documentElement.outerHTML;

        event.stopPropagation();
        event.preventDefault();

        let markdown = NodeHtmlMarkdown.translate(html, {}, {
            'u': {
                spaceIfRepeatingChar: true,
                postprocess: ({ content, options: { strongDelimiter } }) =>
                    content.replace(/\s+/g, '') === ''
                        ? PostProcessResult.RemoveNode
                        : tagSurround(content, '++')
            }
        });
        let textarea = event.target as HTMLTextAreaElement;
        let selectionSpot = textarea.selectionStart;

        if (document.execCommand) {
            // inb4 this is removed, because this supports undo/redo
            document.execCommand?.("insertText", false, markdown);
        } else {
            // modern but no undo/redo
            textarea.setRangeText(markdown);
            textarea.setSelectionRange(selectionSpot + markdown.length, selectionSpot + markdown.length);
        }
    }

    markdownReference() {
        this.shell.showDialog(
            MessageDialogComponent,
            'Markdown Reference',
            `
            <p>Articles on TYT.com support rich text formatting via Markdown formatting tags.
                You can paste content from other applications and the editor will do its best
                to convert the formatting into Markdown, but it may not be perfect. Use the
                following guide to understand how to manually control the formatting of your
                article using Markdown.</p>

            <ul>
                <li>Inline styling: **Bold**, __Italic__, ++Underline++</li>
                <li>Links: [Link Text](https://example.com/)</li>
                <li>Heading 1: <code># Heading Text Here</code></li>
                <li>Heading 2: <code>## Heading Text Here</code></li>
                <li>Heading 3: <code>### Heading Text Here</code></li>
                <li>
                    Unordered lists:
                    <pre>
${''}* First item
${''}* Second item
${''}</pre>
                </li>
                <li>
                    Ordered lists:
                    <pre>
${''}1. First item
${''}2. Second item
${''}</pre>
                </li>
            </ul>
            `
        );
    }
}


/**
 * Surround tag content with delimiter (moving any leading/trailing space to outside the tag
 */
function tagSurround(content: string, surroundStr: string) {
    // If un-escaped surroundStr already occurs, remove all instances
    // See: https://github.com/crosstype/node-html-markdown/issues/18
    const nestedSurroundStrIndex = content.indexOf(surroundStr);
    if (nestedSurroundStrIndex >= 0)
        content = content.replace(
            new RegExp(`([^\\\\])\\${surroundStr.split('').join('\\')}`, 'gm'),
            '$1'
        );

    const lines = splitSpecial(content);
    let res = '';

    for (const { text, newLineChar } of lines) {
        let i: number = 0;
        let startPos: number | undefined = undefined;
        let endPos: number | undefined = undefined;

        while (i >= 0 && i < text.length) {
            if (/[\S]/.test(text[i])) {
                if (startPos === undefined) {
                    startPos = i;
                    i = text.length;
                } else {
                    endPos = i;
                    i = NaN;
                }
            }

            if (startPos === undefined) ++i;
            else --i;
        }

        // If whole string is non-breaking whitespace, don't surround it
        if (startPos === undefined) {
            res += text + newLineChar;
            continue;
        }

        if (endPos === undefined) endPos = text.length - 1;

        const leadingSpace = startPos > 0 ? text[startPos - 1] : '';
        const trailingSpace = endPos < (text.length - 1) ? text[endPos + 1] : '';

        const slicedText = text.slice(startPos, endPos + 1);

        res += leadingSpace + surroundStr + slicedText + surroundStr + trailingSpace + newLineChar;
    }

    return res;

    /**
     * Split string, preserving specific newline used for each line
     */
    function splitSpecial(s: string) {
        const lines: { text: string, newLineChar: '\r' | '\n' | '\r\n' | ''; }[] = [];
        const strLen = s.length;

        for (let i = 0, startPos = 0; i < strLen; ++i) {
            let char = s.charAt(i);
            let newLineChar: typeof lines[number]['newLineChar'] = '';

            if (char === '\r') newLineChar = (s.charAt(i + 1) === '\n') ? '\r\n' : char;
            else if (char === '\n') newLineChar = char;

            const endPos = newLineChar ? i :
                i === (strLen - 1) ? i + 1 :
                    undefined;

            if (endPos === undefined) continue;

            lines.push({
                text: s.slice(startPos, endPos),
                newLineChar
            });

            startPos = endPos + newLineChar.length;
            if (newLineChar.length > 1) ++i;
        }

        return lines;
    }

}

