import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
    templateUrl: './image-cropper-dialog.component.html',
    styleUrls: ['./image-cropper-dialog.component.scss']
})
export class ImageCropperDialogComponent {
    readonly dialogRef = inject(MatDialogRef);

    recommendedAspectRatio = 16/9;
    recommendedAspectRatioLabel: string;
    imageEditorAspectRatio = 16/9;
    capturedImage: Blob;
    imageEditorCallback: (imgUrl: string) => void;
    croppedImageUrl: string;
    backgroundColor = 'black';

    saveCroppedImage() {
        this.imageEditorCallback(this.croppedImageUrl);
        this.close();
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImageUrl = event.base64;
    }

    getSize(image: Blob): Promise<{ width: number, height: number }> {
        return new Promise((resolve, reject) => {
            let img = document.createElement('img') as HTMLImageElement;
            img.onload = () => {
                resolve({ width: img.naturalWidth, height: img.naturalHeight });
            }
            img.onerror = () => {
                reject(new Error('Failed to load image'));
            }
            img.src = URL.createObjectURL(image);
        });

    }

    size: { width: number, height: number } = { width: 1920, height: 1080 };

    crop(image: Blob): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            setTimeout(async () => {
                this.size = await this.getSize(image);
                this.capturedImage = image;
                this.imageEditorCallback = url => resolve(url);
            }, 1000);
        });
    }

    withRecommendedAspectRatio(aspectRatio: number, label: string) {
        this.recommendedAspectRatio = aspectRatio ?? this.recommendedAspectRatio;
        this.recommendedAspectRatioLabel = label ?? this.recommendedAspectRatioLabel;
        return this;
    }

    withDefaultAspectRatio(aspectRatio: number) {
        this.imageEditorAspectRatio = aspectRatio ?? this.imageEditorAspectRatio;
        return this;
    }

    withBackgroundColor(color: string) {
        this.backgroundColor = color;
        return this;
    }

    close() {
        this.dialogRef.close();
    }
}