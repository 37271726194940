import { Component, Input, Output, inject } from '@angular/core';
import { DialogComponent, Icon, NamedDialog, Shell } from '@tytapp/common';
import { Subject } from 'rxjs';

@NamedDialog('icon-editor')
@Component({
    selector: 'tyt-icon-editor-dialog',
    templateUrl: './icon-editor.component.html',
    styleUrls: ['./icon-editor.component.scss']
})
export class IconEditorDialogComponent extends DialogComponent {
    icon: Icon = { type: 'emoji', text: '😀' };
    callback: (icon: Icon) => void;
    availableMaterialIcons: string[];

    private _materialIconQuery: string;
    get materialIconQuery(): string {
        return this._materialIconQuery;
    }

    set materialIconQuery(value) {
        this._materialIconQuery = value;

        // BUG-WORKAROUND
        // NOTE: Having the list get cleared here works around an apparent Angular bug.
        // The crash will occur with "Cannot convert object to primitive value" within Angular's
        // attempt to optimize the change to the list (to avoid re-rendering every item).
        setTimeout(() => {
            this.filteredMaterialIcons = [];
            setTimeout(() => {
                this.filteredMaterialIcons = this.availableMaterialIcons.filter(x => x.includes(this.materialIconQuery));
            })
        });
    }


    filteredMaterialIcons: string[];

    async init(icon: Icon, callback: (icon: Icon) => void) {
        this.icon = { ...(icon ?? { type: 'none' }) };
        this.callback = callback;

        let response = await fetch(`https://raw.githubusercontent.com/google/material-design-icons/master/font/MaterialIcons-Regular.codepoints`);
        let codepoints = response.text();

        this.availableMaterialIcons = (await codepoints).split(/\n/).map(x => x.split(' ')[0]);
        this.filteredMaterialIcons = this.availableMaterialIcons.filter(x => x.includes('te'));
    }

    apply() {
        this.callback(this.icon?.type !== 'none' ? this.icon : undefined);
        this.close();
    }
}

@Component({
    selector: 'tyt-icon-editor',
    template: `
        <tyt-icon
            (click)="openEditor()"
            [defaultIcon]="{ type: 'material', text: 'image' }"
            [icon]="icon"
            ></tyt-icon>
    `,
    styles: [`
        // This is used to produce a decent alignment within a flex row with align-items: baseline, which is needed
        // for mat-form-field to layout well within. It probably isn't right for all icon sizes, so may require changes
        // in future to handle new use cases.
        tyt-icon::before {
            content: '';
            display: inline-block;
            height: calc(100% - 30px);
        }
    `]
})
export class IconEditorComponent {
    private shell = inject(Shell);

    @Input() icon: Icon;
    @Output() iconChange = new Subject<Icon>();

    openEditor() {
        this.shell.showDialog(IconEditorDialogComponent, this.icon, icon => {
            this.icon = icon;
            this.iconChange.next(icon);
        });
    }
}