import { Component, Input, Output } from "@angular/core";
import { ApiTalent, TalentApi } from "@tytapp/api";
import { Subject } from "rxjs";

@Component({
    selector: 'tyt-talent-search',
    templateUrl: './talent-search.component.html',
    styleUrls: ['./talent-search.component.scss']
})
export class TalentSearchComponent {
    constructor(
        private talentApi: TalentApi
    ) {

    }

    private _search: string;
    get search(): string {
        return this._search;
    }

    searchTimeout;
    talent: ApiTalent[];

    @Input() label = 'Talent';

    @Input() placeholder = 'Search for Talent';

    @Output()
    selected = new Subject<ApiTalent>();

    @Input() disabled: boolean;

    add() {
        if (this.talent.length > 0) {
            this.selected.next(this.talent[0]);
            this.search = '';
        }
    }

    set search(value) {
        if (typeof value !== 'string') {
            if (!this.disabled)
                this.selected.next(value);
            this._search = ' ';
            setTimeout(() => this._search = '');
            return;
        }

        this._search = value;
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(async () => {
            this.talent = await this.talentApi.all(this._search, 20).toPromise();
        }, 100);
    }

    async ngOnInit() {
        this.talent = await this.talentApi.all(undefined, 20).toPromise();
    }
}