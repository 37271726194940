import { Component, Input, Output } from "@angular/core";
import { ApiAvAsset, ProductionsApi } from "@tytapp/api";
import { Subject } from "rxjs";

@Component({
    selector: 'tyt-av-asset-search',
    templateUrl: './av-asset-search.component.html',
    styleUrls: ['./av-asset-search.component.scss']
})
export class AvAssetSearchComponent {
    constructor(
        private productionApi: ProductionsApi
    ) {

    }

    private _search: string;
    get search(): string {
        return this._search;
    }

    searchTimeout;
    assets: ApiAvAsset[];

    @Input() label = 'AV Asset';

    private _type: 'audio' | 'video';

    @Input()
    get type() { return this._type; }
    set type(value) {
        this._type = value;
        setTimeout(() => this.fetchResults());
    }

    @Output()
    selected = new Subject<ApiAvAsset>();

    @Input() disabled: boolean;

    add() {

    }

    set search(value) {
        if (typeof value === 'undefined')
            value = '';

        if (typeof value !== 'string') {
            if (!this.disabled) {
                this.selected.next(value);
            }
            this._search = ' ';
            setTimeout(() => this._search = '');
            return;
        }

        this._search = value;
        setTimeout(() => this.fetchResults());
    }

    private fetchResults() {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(async () => {
            if (!['audio', 'video'].includes(this.type)) {
                this.assets = [];
                return;
            }

            this.assets = await this.productionApi.getVideoAssets(this.type, this._search).toPromise();
        }, 100);
    }
}