import { Component, Input, Output } from '@angular/core';
import { getDateAtLocalMidnight, getDateString, getNextMondayAtLocalMidnight, getTodayAtLocalMidnight, getTomorrowAtLocalMidnight, parseDateAtLocalMidnight, zeroPad } from '@tytapp/common';
import { Subject } from 'rxjs';

/**
 * Provides a date picker that is strictly for dates, without time zones.
 * Supports both Date objects and strings.
 * - For Date objects ('value'), local midnight is
 *   used for the time portion of all dates (if any time portion is included when setting
 *   'value'), the time portion is rewritten to be local midnight.
 * - For string objects ('stringValue'), always outputs YYYY-MM-DD (ie with no time or
 *   time zone information). If a string is provided in any other format, it is first
 *   parsed using new Date(string), and then conformed to local midnight, and the resulting
 *   date is selected. Therefore, if the date string has time zone information it will be respected
 *   when determining what date the value represents, but that time zone information will be discarded
 *   when reading back the date from `stringValue`, or reading it as a Date object from `value`
 */
@Component({
    selector: 'tyt-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent {
    @Input() disabled: boolean;
    @Input() label: string;

    private _value: Date;

    @Input() get value() {
        return this._value;
    }

    set value(value) {
        if (!value)
            this._value = null;
        else
            this._value = getDateAtLocalMidnight(value);

        setTimeout(() => {
            this.valueChange.next(this._value);
            this.stringValueChange.next(this.stringValue);
        });
    }

    @Output() valueChange = new Subject<Date>();

    @Input()
    get stringValue(): string {
        if (this.value)
            return getDateString(this.value);
        return null;
    }

    set stringValue(value) {
        if (!value)
            this._value = null;
        else
            this._value = parseDateAtLocalMidnight(value);

        setTimeout(() => {
            this.valueChange.next(this._value);
            this.stringValueChange.next(this.stringValue);
        });
    }

    @Output() stringValueChange = new Subject<string>();

    setValue(date: Date) {
        this.value = date;
    }

    setDateToday() {
        this.value = getTodayAtLocalMidnight();
    }

    setDateTomorrow() {
        this.value = getTomorrowAtLocalMidnight();
    }

    setDateNextMonday() {
        this.value = getNextMondayAtLocalMidnight();
    }

}