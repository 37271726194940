import { Component, HostBinding, inject, Input, Output } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { ImageAssetsApi } from "@tytapp/api";
import { ImageCropperDialogComponent } from '../image-cropper-dialog/image-cropper-dialog.component';
import { isClientSide } from '@tytapp/environment-utils';
import { Subject } from "rxjs";

@Component({
    selector: 'tyt-image-asset',
    templateUrl: './image-asset.component.html',
    styleUrls: ['./image-asset.component.scss']
})
export class ImageAssetComponent {
    private imageAssetApi = inject(ImageAssetsApi);
    private matDialog = inject(MatDialog);

    inBrowser = isClientSide();

    @Input() url: string;
    @Output() urlChange = new Subject<string>();
    savingImage = false;

    @Input() storageMethod: 'asset' | 'data-url' = 'asset';

    @Input()
    recommendedAspectRatio: number;

    @Input()
    recommendedAspectRatioLabel: string;

    @HostBinding('class.disabled')
    @Input()
    disabled: boolean;

    @Input() @HostBinding('style.aspect-ratio')
    aspectRatio: number;

    loading = false;
    async chooseFile() {
        if (this.disabled)
            return;

        let url = await this.uploadAndCropImage();

        this.loading = true;

        try {
            if (this.storageMethod === 'data-url') {
                this.url = url;
                this.urlChange.next(this.url);
            } else if (this.storageMethod === 'asset') {
                let imageAsset = await this.imageAssetApi.upload({ url }).toPromise();
                this.url = imageAsset.url;
                this.urlChange.next(this.url);
            }
        } finally {
            this.loading = false;
        }
    }

    uploadAndCropImage(): Promise<string> {
        return new Promise((resolve, reject) => {
            let input = document.createElement('input');

            input.setAttribute('type', 'file');
            input.setAttribute('accept', '.png,.jpg,.jpeg,.bmp');
            input.addEventListener('change', async () => {
                if (input.files.length === 0)
                    return;

                resolve(
                    await this.matDialog
                        .open(ImageCropperDialogComponent)
                        .componentInstance
                        .withRecommendedAspectRatio(this.recommendedAspectRatio, this.recommendedAspectRatioLabel)
                        .withDefaultAspectRatio(this.aspectRatio)
                        .crop(input.files[0])
                );
            });
            input.click();
        });
    }
}