<mat-menu #cropTypeMenu>
  @if (recommendedAspectRatio) {
    <button href="javascript:;" mat-menu-item title="Freeform" (click)="imageEditorAspectRatio = recommendedAspectRatio">
      <mat-icon>aspect_ratio</mat-icon>
      Recommended ({{recommendedAspectRatioLabel}})
    </button>
  }
  <button href="javascript:;" mat-menu-item title="Freeform" (click)="imageEditorAspectRatio = undefined">
    <mat-icon>aspect_ratio</mat-icon>
    Freeform
  </button>
  <button href="javascript:;" mat-menu-item title="Square" (click)="imageEditorAspectRatio = 1">
    <mat-icon>crop_square</mat-icon>
    Square
  </button>
  <button href="javascript:;" mat-menu-item title="3:2" (click)="imageEditorAspectRatio = 3/2">
    <mat-icon>crop_3_2</mat-icon>
    3:2
  </button>
  <button href="javascript:;" mat-menu-item title="5:4" (click)="imageEditorAspectRatio = 5/4">
    <mat-icon>crop_5_4</mat-icon>
    5:4
  </button>
  <button href="javascript:;" mat-menu-item title="16:9" (click)="imageEditorAspectRatio = 16/9">
    <mat-icon>crop_16_9</mat-icon>
    16:9
  </button>
  <button href="javascript:;" mat-menu-item title="7:5" (click)="imageEditorAspectRatio = 7/5">
    <mat-icon>crop_7_5</mat-icon>
    7:5
  </button>
</mat-menu>
<div class="toolbar">
  <button [matMenuTriggerFor]="cropTypeMenu" mat-button>
    <mat-icon>crop</mat-icon>
    Crop Type
  </button>
  <div class="spacer"></div>
  <button mat-icon-button class="close-button" href="javascript:;" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<image-cropper
  [imageFile]="capturedImage | as: 'any'"
  [maintainAspectRatio]="!!imageEditorAspectRatio"
  [aspectRatio]="imageEditorAspectRatio || (16/9)"
  format="png"
  (imageCropped)="imageCropped($event)"
  [style.background-color]="backgroundColor"
></image-cropper>

<div style="text-align: right;">
  <button mat-raised-button href="javascript:;" (click)="saveCroppedImage()">
    <mat-icon>save</mat-icon>
    Save
  </button>
</div>