import { Component, Input, Output, inject } from '@angular/core';
import { rewriteUrl } from '@tytapp/environment-utils';
import { environment } from '@tytapp/environment';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

export const URL_PATTERN = '^/.*$|^https://([A-Za-z0-9-]+\\.)+[A-Za-z0-9-]+($|\\/.*$)';

@Component({
    selector: 'tyt-url-field',
    template: `
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{label}}</mat-label>
          <input type="text" matInput [ngModel]="value" (ngModelChange)="valueChange.next($event)" pattern="${URL_PATTERN}" [disabled]="disabled" />
          <mat-hint>
            @if (!value) {
              {{ hint }}
            }
            @if (value) {
              Make sure it works! <a [href]="rewrittenUrl" target="_blank">Click here</a> to test link in new tab
            }
          </mat-hint>
          <mat-error>
            {{ errorMessage }}
          </mat-error>
        </mat-form-field>
        `,
    styles: [`
        mat-form-field {
            width: 100%;
        }
    `]
})
export class UrlFieldComponent {
    private router = inject(Router);

    @Input() label: string = 'Link';
    @Input() hint = '';
    @Input() disabled = false;

    @Input() value: string;
    @Output() valueChange = new Subject<string>();

    get rewrittenUrl() {
        return rewriteUrl(environment, this.value);
    }

    get errorMessage() {
        if (!this.value)
            return `Please enter a URL`;

        if (this.value.startsWith('http://')) {
            return `Only https:// URLs are supported.`;
        }

        if (!this.value.startsWith('/') && !this.value.startsWith('https://'))
            return `URL must begin with "https://"`;

        return `Invalid URL`;
    }
}