<form (submit)="add()">
  <mat-form-field appearance="outline" floatLabel="always">
    <mat-label>{{label}}</mat-label>
    <input type="text" matInput [matAutocomplete]="auto" name="search" [(ngModel)]="search"
      placeholder="Search for Asset" [disabled]="disabled" />
    </mat-form-field>
    <!-- <button mat-icon-button matTooltip="Add New">
    <mat-icon>add</mat-icon>
  </button> -->
</form>
<mat-autocomplete #auto="matAutocomplete">
  @for (asset of assets; track asset) {
    <mat-option [value]="asset">
      {{asset.title}} [{{asset.provider}}:{{asset.identifier}}]
    </mat-option>
  }
</mat-autocomplete>