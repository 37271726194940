import { Component, Input, Output } from '@angular/core';
import { Link } from '@tytapp/common';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
    selector: 'tyt-link-editor',
    templateUrl: './link-editor.component.html',
    styleUrl: './link-editor.component.scss'
})
export class LinkEditorComponent {
    @Input({ required: true }) link?: Link;
    @Output() linkChange = new Subject<Link | undefined>();

    delete() {
        this.link = undefined;
        this.linkChange.next(undefined);
    }

    create() {
        this.link = { url: '' };
        this.linkChange.next(this.link);
    }
}