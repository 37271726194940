@if (link) {
  <div class="field-row">
    <tyt-icon-editor [(icon)]="link.icon" />
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>URL</mat-label>
      <input type="url" matInput [(ngModel)]="link.url" />
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Label</mat-label>
      <input type="label" matInput [(ngModel)]="link.label" />
    </mat-form-field>
  </div>

  <button mat-icon-button (click)="delete()">
    <mat-icon>close</mat-icon>
  </button>
} @else {
  <button mat-raised-button (click)="create()">Add Link</button>
}