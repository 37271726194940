<a class="overlay" href="javascript:;" (click)="chooseFile()" [class.disabled]="disabled" aria-label="Upload image">
  @if (loading) {
    <app-spinner></app-spinner>
  }
  @if (!loading) {
    @if (url) {
      <img class="preview" [src]="url" [style.aspect-ratio]="aspectRatio" />
    }
    @if (!url) {
      <div class="placeholder" [style.aspect-ratio]="recommendedAspectRatio ?? aspectRatio">
        <mat-icon>image</mat-icon>
      </div>
    }
  }
</a>