"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __exportStar = this && this.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasChildren = exports.isDocument = exports.isComment = exports.isText = exports.isCDATA = exports.isTag = void 0;
__exportStar(require("./stringify.js"), exports);
__exportStar(require("./traversal.js"), exports);
__exportStar(require("./manipulation.js"), exports);
__exportStar(require("./querying.js"), exports);
__exportStar(require("./legacy.js"), exports);
__exportStar(require("./helpers.js"), exports);
__exportStar(require("./feeds.js"), exports);
/** @deprecated Use these methods from `domhandler` directly. */
var domhandler_1 = require("domhandler");
Object.defineProperty(exports, "isTag", {
  enumerable: true,
  get: function () {
    return domhandler_1.isTag;
  }
});
Object.defineProperty(exports, "isCDATA", {
  enumerable: true,
  get: function () {
    return domhandler_1.isCDATA;
  }
});
Object.defineProperty(exports, "isText", {
  enumerable: true,
  get: function () {
    return domhandler_1.isText;
  }
});
Object.defineProperty(exports, "isComment", {
  enumerable: true,
  get: function () {
    return domhandler_1.isComment;
  }
});
Object.defineProperty(exports, "isDocument", {
  enumerable: true,
  get: function () {
    return domhandler_1.isDocument;
  }
});
Object.defineProperty(exports, "hasChildren", {
  enumerable: true,
  get: function () {
    return domhandler_1.hasChildren;
  }
});
