"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PostProcessResult = exports.TranslatorCollection = exports.NodeHtmlMarkdown = void 0;
var main_1 = require("./main");
Object.defineProperty(exports, "NodeHtmlMarkdown", {
  enumerable: true,
  get: function () {
    return main_1.NodeHtmlMarkdown;
  }
});
var translator_1 = require("./translator");
Object.defineProperty(exports, "TranslatorCollection", {
  enumerable: true,
  get: function () {
    return translator_1.TranslatorCollection;
  }
});
Object.defineProperty(exports, "PostProcessResult", {
  enumerable: true,
  get: function () {
    return translator_1.PostProcessResult;
  }
});
