"use strict";

// Generated using scripts/write-decode-map.ts
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = new Uint16Array(
// prettier-ignore
"\u0200aglq\t\x15\x18\x1b\u026d\x0f\0\0\x12p;\u4026os;\u4027t;\u403et;\u403cuot;\u4022".split("").map(function (c) {
  return c.charCodeAt(0);
}));
